<template>
  <div class="loaders">
    <div class="loader">
      <div class="loader-inner ball-clip-rotate-multiple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
document.addEventListener("DOMContentLoaded", function () {
  document.querySelector("div").className += "loaded";
});
export default {
  name: "loadAnimation"
};
</script>

<style lang="scss" scoped>
/**
 *
 * All animations must live in their own file
 * in the animations directory and be included
 * here.
 *
 */
.loaded {
  transition: opacity 0.25s linear;
  opacity: 1;
}

.loaders {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  color: #fff;
  font-size: 16px;
  font-family: "Source Sans Pro";
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaders .loader {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.ball-clip-rotate-multiple {
  position: relative;
}

.ball-clip-rotate-multiple>div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 2px solid $colorMain;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  -webkit-animation: rotate 1s 0s ease-in-out infinite;
  animation: rotate 1s 0s ease-in-out infinite;
}

.ball-clip-rotate-multiple>div:last-child {
  display: inline-block;
  top: 10px;
  left: 10px;
  width: 15px;
  height: 15px;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  border-color: #285fde transparent #285fde transparent;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

@-webkit-keyframes ball-scale-ripple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ball-scale-ripple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
</style>
